import { memo, useCallback, useEffect, useState } from "react";

import CameraAltIcon from "@material-ui/icons/CameraAlt";

import { useScreenshotContext, useScreenshotDispatch } from "components/screenshot/hooks";

import { ToolbarButton } from "../components";

const ScreenshotToggleButton = ({ onToggle, type }) => {
  const screenshotDispatch = useScreenshotDispatch();
  const { settings, widget } = useScreenshotContext();

  const [screenshotOverlayVisible, setScreenshotOverlayVisible] = useState(false);

  useEffect(() => {
    const screenshotActive = widget?.widgetId === type;
    setScreenshotOverlayVisible(screenshotActive);
  }, [settings, widget]);

  useEffect(() => {
    const typeToComponentType = {
      ml: "threeDViewer",
      xda: "xdaViewer"
    };

    const componentType = typeToComponentType[type];

    const widget = screenshotOverlayVisible
      ? {
          widgetId: type,
          widgetComponentType: componentType
        }
      : null;

    screenshotDispatch({
      payload: {
        widget
      }
    });
  }, [screenshotDispatch, screenshotOverlayVisible]);

  const toggleScreenshot = useCallback(() => {
    onToggle && onToggle(!screenshotOverlayVisible);
    setScreenshotOverlayVisible(!screenshotOverlayVisible);
  }, [screenshotOverlayVisible]);

  return (
    <ToolbarButton
      key="Screenshot"
      active={screenshotOverlayVisible}
      icon={<CameraAltIcon fontSize="large" />}
      tooltipText="Screenshot"
      onToggle={toggleScreenshot}
    />
  );
};

export default memo(ScreenshotToggleButton);
