import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Collapse, Input, Select, Slider, Switch } from "antd";
import _debounce from "lodash/debounce";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import useUserModules from "hooks/useUserModules";

import { ThreeDViewerOptionT } from "models";

import usePlayTo3DDataSources from "../../hooks/usePlayTo3DDataSources";
import usePlayTo3DFields from "../../hooks/usePlayTo3DFields";
import getGeoMapLayers from "../geo-map/hooks/getGeoMapLayers";
import { useVisState } from "./context";

const { Option } = Select;
const { Panel } = Collapse;

const env = process.env.REACT_APP_ENV;

function ThreeDViewerOptions(props: ThreeDViewerOptionT): JSX.Element {
  // const [maxWidth, setMaxWidth] = useState(props.maxWidth);
  // const [maxHeight, setMaxHeight] = useState(props.maxHeight);
  // const [maxValue, setMaxValue] = useState(props.maxValue);
  const [colorMapMin, setColorMapMin] = useState<string>(
    props.colorMapMin?.toString() ?? ""
  );
  const [colorMapMax, setColorMapMax] = useState<string>(
    props.colorMapMax?.toString() ?? ""
  );

  const { data: mapLayers } = getGeoMapLayers();
  const { has3dGeoModel } = useUserModules();

  const mcdanielGroup = [
    {
      label: "McDaniel",
      options: [{ label: "McDaniel", value: "McDaniel" }]
    }
  ];
  const [modelSources, setModelSources] = useState(mcdanielGroup);
  const [{ xda }] = useVisState();
  const { ipdbSource: xdaIpdbSource, ipdbField: xdaField } = xda.settings;
  const { data: dataSources, refetch: refetchDataSources } = usePlayTo3DDataSources();
  const [ipdbField, setIpdbField] = useState(xdaField);
  const [ipdbSource, setIpdbSource] = useState(xdaIpdbSource);
  const { data: fields, refetch } = usePlayTo3DFields(ipdbSource);
  useEffect(() => {
    refetch();
    refetchDataSources();
  }, []);
  const debouncedOnChange = useCallback(
    _debounce((val, refresh) => {
      props.onOptionChange && props.onOptionChange(val, refresh);
    }, 500),
    [props.onOptionChange]
  );
  useEffect(() => {
    const ipdbFieldChanged = xda.settings.ipdbField != ipdbField;
    const ipdbSourceChanged = xda.settings.ipdbSource != ipdbSource;
    if (ipdbFieldChanged && ipdbSourceChanged) {
      setIpdbField(xda.settings.ipdbField);
      setIpdbSource(xda.settings.ipdbSource);
      debouncedOnChange(
        { ipdbField: xda.settings.ipdbField, ipdbSource: xda.settings.ipdbSource },
        true
      );
    } else if (ipdbFieldChanged) {
      setIpdbField(ipdbField);
      debouncedOnChange({ ipdbField: ipdbField }, false);
    } else if (ipdbSourceChanged) {
      setIpdbSource(ipdbSource);
      debouncedOnChange({ ipdbSource: ipdbSource }, true);
    }
  }, [xda.settings.ipdbField, xda.settings.ipdbSource]);

  useEffect(() => {
    const orgGroups = {
      label: "Organization",
      options: []
    };
    if (mapLayers?.length > 0) {
      for (const item of mapLayers) {
        if (
          orgGroups.options.findIndex((o) => o.value === item.group) === -1 &&
          item.hasLsdMap
        ) {
          orgGroups.options.push({ label: item.group, value: item.group });
        }
      }
    }
    setModelSources([...mcdanielGroup, orgGroups]);
  }, [mapLayers]);
  const visSettings = useSelector((state: RootState) => state.app.visSettings);

  useEffect(() => {
    if (!props.onOptionChange) return;

    const { width, height } = visSettings;
    props.onOptionChange({ defaultWidth: width, defaultHeight: height }, true);
  }, [visSettings]);

  return (
    <RootContainer>
      <OptionItem>
        <label>Scale Z</label>
        <Slider
          className="action"
          value={props.scaleZ}
          min={1}
          max={40}
          onChange={(e) => {
            props.onOptionChange && props.onOptionChange({ scaleZ: e }, false);
          }}
        />
      </OptionItem>
      <OptionItem>
        <label>Line Width</label>
        <Slider
          className="action"
          value={props.lineWidth}
          min={1}
          max={10}
          onChange={(e) => {
            props.onOptionChange && props.onOptionChange({ lineWidth: e }, false);
          }}
        />
      </OptionItem>
      <OptionItem>
        <label>Tops Model Source</label>
        <Select
          size="small"
          value={props.modelSource}
          options={modelSources}
          onChange={(e) => {
            props.onOptionChange && props.onOptionChange({ modelSource: e }, true);
          }}
        />
      </OptionItem>
      <OptionItem>
        <label>Show Zone Tops</label>
        <Switch
          className="action"
          size="small"
          checked={props.showIPDBPlane}
          onChange={(e) => {
            props.onOptionChange && props.onOptionChange({ showIPDBPlane: e }, false);
          }}
        />
      </OptionItem>

      <OptionItem>
        <label>Show Vertical Portion of Hz Well</label>
        <Switch
          className="action"
          size="small"
          checked={props.showVerticalPortion}
          onChange={(e) => {
            props.onOptionChange &&
              props.onOptionChange({ showVerticalPortion: e }, false);
          }}
        />
      </OptionItem>

      {(env === "mcdan" || has3dGeoModel) && (
        <>
          <OptionItem>
            <label>Show Vertical Wells</label>
            <Switch
              className="action"
              size="small"
              checked={props.showVertical}
              onChange={(e) => {
                props.onOptionChange && props.onOptionChange({ showVertical: e }, true);
              }}
            />
          </OptionItem>
          <OptionItem>
            <label>Show Well Volume</label>
            <Switch
              className="action"
              size="small"
              checked={props.showDrainage}
              onChange={(e) => {
                props.onOptionChange && props.onOptionChange({ showDrainage: e }, true);
              }}
            />
          </OptionItem>
          <Collapse accordion>
            <Panel
              header="Well Geo Values"
              key="IPDB"
              collapsible={props.showDrainage ? "header" : "disabled"}
              extra={
                <Switch
                  className="action"
                  disabled={!props.showDrainage}
                  checked={props.showDrainage && props.showIPDBDrainage}
                  onChange={(e) => {
                    props.onOptionChange &&
                      props.onOptionChange({ showIPDBDrainage: e }, true);
                  }}
                />
              }>
              <OptionItem>
                <label>Model Source</label>
                <Select
                  size="small"
                  value={ipdbSource}
                  options={dataSources?.map((source) => ({
                    label: source,
                    value: source
                  }))}
                  onChange={(source) => {
                    setIpdbSource(source);
                    props.onOptionChange({ ipdbSource: source }, true);
                  }}
                />
              </OptionItem>

              <OptionItem>
                <Label>Field</Label>
                <Select
                  size="small"
                  value={ipdbField}
                  options={fields?.map((field) => ({
                    label: field.displayName,
                    value: field.displayName,
                    title: field.tooltip ?? field.displayName
                  }))}
                  onChange={(value) => {
                    setIpdbField(value);
                    const field = fields?.find((f) => f.displayName === value);
                    props.onOptionChange &&
                      props.onOptionChange(
                        {
                          ipdbField: value,
                          ipdbFieldUnit: field?.unit ?? ""
                        },
                        false
                      );
                  }}
                />
              </OptionItem>

              <OptionItem>
                <label>Color</label>
                <Select
                  value={props.colorMap}
                  onChange={(e) => {
                    props.onOptionChange && props.onOptionChange({ colorMap: e }, false);
                  }}>
                  <Option value="rainbow" key="rainbow">
                    Rainbow
                  </Option>
                  <Option value="cooltowarm" key="cooltowarm">
                    Cool to Warm
                  </Option>
                  <Option value="blackbody" key="blackbody">
                    Blackbody
                  </Option>
                </Select>
              </OptionItem>

              <OptionItem>
                <label>Min</label>
                <Input
                  value={colorMapMin}
                  onChange={(e) => {
                    setColorMapMin(e.target.value);
                    debouncedOnChange(
                      {
                        colorMapMin: e.target.valueAsNumber
                      },
                      false
                    );
                  }}
                  type="number"
                />
              </OptionItem>

              <OptionItem>
                <label>Max</label>
                <Input
                  value={colorMapMax}
                  onChange={(e) => {
                    setColorMapMax(e.target.value);
                    debouncedOnChange(
                      {
                        colorMapMax: e.target.valueAsNumber
                      },
                      false
                    );
                  }}
                  type="number"
                />
              </OptionItem>
            </Panel>
          </Collapse>
        </>
      )}
    </RootContainer>
  );
}

export default ThreeDViewerOptions;

const RootContainer = styled.div`
  min-width: 280px;
  border-radius: var(--border-radius);

  .ant-card-body {
    padding: 5px;
  }

  .ant-select {
    min-width: 180px;
    width: 180px;
    align-self: flex-end;
  }
`;

const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0;

  .ant-slider {
    width: 80px;
    margin: 5px 0;
  }

  .ant-select {
    min-width: 140px;
  }

  .ant-input {
    height: 25px;
    max-width: 80px;
  }
`;

const Label = styled.label`
  min-width: 120px;
`;
