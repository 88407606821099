import * as portals from "react-reverse-portal";

import { DEFAULT_CHART_STATE, MidstreamChartTypes } from "constants/chart.constants";
import styled from "styled-components/macro";

import { GridWidget } from "models/dashboard";

import { TypeWellDetailsWidget } from "components/arps/widget/TypeWellDetails/TypeWellDetailsWidget";
import { TypeWellEditorWidget } from "components/arps/widget/TypeWellEditor/TypeWellEditorWidget";
import ChartWrapper from "components/chart/ChartWrapper";
import GlobalSettingsWrapper from "components/chart/GlobalSettingsWrapper";
import { DataTable, TableProvider } from "components/data-table";
import ExploitableReport from "components/exploitable-report/ExploitableReport";
import { GeoMap } from "components/geo-map/GeoMap";
import { GroupByProvider } from "components/groupBy/group-by.context";
import MapBox from "components/map/Map";
import { IpdbProvider } from "components/map/contexts/IpdbContext";
import Ml from "components/ml/Ml";
import { MultiPhaseChart } from "components/multiphase-chart/components";
import MPCSpecificSettingsWrapper from "components/multiphase-chart/components/chart/MPCSpecificSettingsWrapper";
import RFvsPVChart from "components/sam/RFvsPVChart";
import SamCharts from "components/sam/SamCharts";
import SamTable from "components/sam/SamTable";
import {
  Info,
  ThreeDViewer,
  VisProvider,
  WellCard,
  WellCompletions3dView,
  WellDetailsProvider,
  WellSurveyVisualizer,
  XdaViewer
} from "components/vis";

import { MidstreamProductGroups } from "../../models/chart";
import { EntityKind } from "../../models/entityKind";
import { AllChartCapabilities, ChartProvider } from "../chart/context";
import { ChartSettingsProvider as MultiPhaseChartSettingsProvider } from "../multiphase-chart/context";
import {
  MidstreamChartWidgetKey,
  MidstreamDataTableWidgetKey,
  TypeWellDetailsWidgetKey,
  TypeWellEditorWidgetKey
} from "./constants/widgets.constants";

export interface WidgetInputModel {
  widget: GridWidget;
  showFullscreen: boolean;
  selectedWells: [];
  wells: Record<string, unknown>;
  onFullscreen: (b: boolean) => void;
}

function Widget(input: WidgetInputModel) {
  if (!input.widget?.component) return null;

  const fullScreenMarkup = !input.showFullscreen && (
    <Wrapper className="portal">
      <portals.OutPortal node={input.widget.componentOptions?.portalNode} />
    </Wrapper>
  );
  return (
    <>
      {input.widget.component === "map" && (
        <Wrapper>
          <portals.InPortal node={input.widget.componentOptions.portalNode}>
            <MapBox
              id={input.widget.name}
              onFullscreen={(fullscreen) => input.onFullscreen(fullscreen)}
            />
          </portals.InPortal>
          {fullScreenMarkup}
        </Wrapper>
      )}

      {input.widget.component === "xda" && (
        <IpdbProvider>
          <Wrapper>
            <portals.InPortal node={input.widget.componentOptions.portalNode}>
              <XdaViewer
                onFullscreenToggle={(fullscreen) => input.onFullscreen(fullscreen)}
              />
            </portals.InPortal>
            {fullScreenMarkup}
          </Wrapper>
        </IpdbProvider>
      )}

      {input.widget.component === "threed" && (
        <IpdbProvider>
          <Wrapper>
            <portals.InPortal node={input.widget.componentOptions.portalNode}>
              <ThreeDViewer
                wells={input.wells}
                onFullscreenToggle={(fullscreen) => input.onFullscreen(fullscreen)}
              />
            </portals.InPortal>
            {fullScreenMarkup}
          </Wrapper>
        </IpdbProvider>
      )}
      {input.widget.component === "ml" && (
        <div className="three-d-container">
          <Ml />
        </div>
      )}

      {input.widget.component === "data-table" && (
        <TableProvider entityKind={EntityKind.Well}>
          <DataTable id={input.widget.ref} initialState={input.widget.componentState} />
        </TableProvider>
      )}
      {input.widget.component === MidstreamDataTableWidgetKey && (
        <TableProvider entityKind={EntityKind.Facility}>
          <DataTable id={input.widget.ref} initialState={input.widget.componentState} />
        </TableProvider>
      )}
      {input.widget.component === "chart" && (
        <Wrapper>
          <portals.InPortal node={input.widget.componentOptions.portalNode}>
            <ChartProvider
              initialState={{
                ...DEFAULT_CHART_STATE,
                settings: input.widget.componentState,
                availableChartProductGroups: [
                  "Sales",
                  "Summary",
                  "Wellhead",
                  "Injection"
                ],
                chartCapabilities: [
                  AllChartCapabilities.DataSourceSelection,
                  AllChartCapabilities.Forecast,
                  AllChartCapabilities.CD_PD,
                  AllChartCapabilities.TypeWell,
                  AllChartCapabilities.TimeStep,
                  AllChartCapabilities.Normalize
                ],
                entityKind: EntityKind.Well
              }}>
              <ChartWrapper
                id={input.widget.ref}
                onFullscreen={(fullscreen) => input.onFullscreen(fullscreen)}
              />
              <GlobalSettingsWrapper message="Chart-specific settings" />
            </ChartProvider>
          </portals.InPortal>
          {fullScreenMarkup}
        </Wrapper>
      )}

      {input.widget.component === MidstreamChartWidgetKey &&
        input.widget?.componentOptions?.portalNode && (
          <Wrapper>
            <portals.InPortal node={input.widget.componentOptions.portalNode}>
              <ChartProvider
                initialState={{
                  ...DEFAULT_CHART_STATE,
                  settings: input.widget.componentState,
                  availableChartTypes: MidstreamChartTypes,
                  availableChartProductGroups: MidstreamProductGroups,
                  chartCapabilities: [],
                  entityKind: EntityKind.Facility
                }}>
                <ChartWrapper
                  type="Midstream"
                  id={input.widget.ref}
                  onFullscreen={(fullscreen) => input.onFullscreen(fullscreen)}
                />
                <GlobalSettingsWrapper message="Chart-specific settings" />
              </ChartProvider>
            </portals.InPortal>
            {fullScreenMarkup}
          </Wrapper>
        )}

      {input.widget.component === "info" && (
        <Info
          id={input.widget.ref}
          selectedWell={Object.keys(input.selectedWells)[0]}
          initialPropertyName={input.widget.componentState["AggregateField"]}
          initialForecastToggle={input.widget.componentState["IsForecastToggleOn"]}
          initialForecastFolder={input.widget.componentState["SelectedForecastFolder"]}
        />
      )}

      {input.widget.component === "well-card" && (
        <GroupByProvider>
          <WellDetailsProvider>
            <WellCard entityKind={EntityKind.Well} />
          </WellDetailsProvider>
        </GroupByProvider>
      )}

      {input.widget.component === "facility-ticket" && (
        <GroupByProvider>
          <WellDetailsProvider>
            <WellCard entityKind={EntityKind.Facility} />
          </WellDetailsProvider>
        </GroupByProvider>
      )}

      {input.widget.component === "xdaviewer" && (
        <VisProvider>
          <Wrapper>
            <portals.InPortal node={input.widget.componentOptions.portalNode}>
              <XdaViewer
                onFullscreenToggle={(fullscreen) => input.onFullscreen(fullscreen)}
              />
            </portals.InPortal>
            {fullScreenMarkup}
          </Wrapper>
        </VisProvider>
      )}

      {input.widget.component === "survey-vis" && (
        <Wrapper>
          <portals.InPortal node={input.widget.componentOptions.portalNode}>
            <WellSurveyVisualizer
              well={Object.keys(input.selectedWells)[0]}
              onFullscreenToggle={(fullscreen) => input.onFullscreen(fullscreen)}
            />
          </portals.InPortal>
          {fullScreenMarkup}
        </Wrapper>
      )}

      {input.widget.component === "completion" && (
        <Wrapper>
          <portals.InPortal node={input.widget.componentOptions.portalNode}>
            <WellCompletions3dView
              uwid={input.wells ? Object.keys(input.wells)[0] : ""}
              onFullscreenToggle={(fullscreen) => input.onFullscreen(fullscreen)}
            />
          </portals.InPortal>
          {fullScreenMarkup}
        </Wrapper>
      )}
      {input.widget.component === "exploitable-report" && <ExploitableReport />}
      {input.widget.component === "sam" && (
        <ChartProvider initialState={DEFAULT_CHART_STATE}>
          <SamCharts id={input.widget.ref} />
        </ChartProvider>
      )}
      {input.widget.component === "sam-table" && <SamTable />}
      {input.widget.component === "sam-rf-pv" && (
        <ChartProvider initialState={DEFAULT_CHART_STATE}>
          <RFvsPVChart id={input.widget.ref} />
        </ChartProvider>
      )}
      {input.widget.component === "multiphase-chart" && (
        <Wrapper>
          <portals.InPortal node={input.widget.componentOptions.portalNode}>
            <MultiPhaseChartSettingsProvider initialState={input.widget.componentState}>
              <MultiPhaseChart
                id={input.widget.ref}
                onFullscreenToggle={(fullscreen) => input.onFullscreen(fullscreen)}
                type={input.widget.component}
              />
              <MPCSpecificSettingsWrapper message="Chart-specific settings" />
            </MultiPhaseChartSettingsProvider>
          </portals.InPortal>
          {fullScreenMarkup}
        </Wrapper>
      )}
      {input.widget.component === TypeWellEditorWidgetKey && (
        <Wrapper>
          <TypeWellEditorWidget />
        </Wrapper>
      )}
      {input.widget.component === TypeWellDetailsWidgetKey && (
        <Wrapper>
          <TypeWellDetailsWidget />
        </Wrapper>
      )}
      {input.widget.component === "geo-map" && (
        <Wrapper>
          <GeoMap />
        </Wrapper>
      )}
    </>
  );
}

export default Widget;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
