// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useRef, useState } from "react";

import { mdiAlert, mdiCalendarClock } from "@mdi/js";
import { Button, Input, Select, Table } from "antd";
import { ALL_PRODUCT_TYPES } from "constants/chart.constants";
import styled from "styled-components";

import { useCategories } from "hooks";

import {
  DataSourcePerformanceResult,
  WellPerformanceDataAnalyzerResult
} from "api/appTools";

import { Tooltip } from "components/base";
import { GroupByProvider } from "components/groupBy/group-by.context";

import { useSelectedWellFromStore, useWellPerformanceDataAnalyzer } from "./hooks";

const PerformanceDataAnalyzer = () => {
  // State
  const [uwi, setUwi] = useState("");
  const [product, setProduct] = useState("Oil");
  const [column, setColumn] = useState("EUR");
  const [data, setData] = useState<WellPerformanceDataAnalyzerResult>(null);
  const [analyzerError, setAnalyzerError] = useState<string>(null);
  const [analyzerErrorDetails, setAnalyzerErrorDetails] = useState<string>(null);
  const [showAnalyzerErrorDetails, setShowAnalyzerErrorDetails] =
    useState<boolean>(false);

  // Refs
  const uwiInputRef = useRef(null);

  // Hooks
  const selectedWell = useSelectedWellFromStore();
  const { categories } = useCategories();
  const { mutate, isLoading, isError } = useWellPerformanceDataAnalyzer(
    (data) => {
      setData(data);
    },
    (err) => {
      if (err.response?.data) {
        setAnalyzerErrorDetails(err.response?.data);
      } else {
        setAnalyzerErrorDetails(err.message);
      }
      setAnalyzerError("Failed to analyze performance data");
    }
  );

  // Callbacks
  const handleUwiChange = useCallback((e) => {
    setUwi(e.target.value);
  }, []);

  const startAnalysis = useCallback(() => {
    setAnalyzerError(null);
    setShowAnalyzerErrorDetails(false);
    mutate({
      uwi,
      settings: {
        performanceProperty: `Performance.${product}.${column}`,
        product: ""
      }
    });
  }, [uwi, product, column]);

  // Effects
  useEffect(() => {
    if (uwiInputRef.current) {
      uwiInputRef.current.focus();
    }
  }, [uwiInputRef]);

  useEffect(() => {
    setUwi(selectedWell?.Uwi);
  }, [selectedWell?.Uwi]);

  // Table Columns
  const tableColumns = [
    {
      dataIndex: "pdenDataSource",
      key: "pdenDataSource",
      title: "Pden Data Source"
    },
    {
      dataIndex: "forecastSource",
      key: "forecastSource",
      title: "Forecast Source",
      render: (text) => {
        if (text.startsWith("unknown project")) {
          return (
            <span>
              <i>unknown project</i>
              {text.replace("unknown project", "")}
            </span>
          );
        } else if (text.startsWith("unknown folder")) {
          return (
            <span>
              <i>unknown folder</i>
              {text.replace("unknown folder", "")}
            </span>
          );
        }
        return <span>{text}</span>;
      }
    },
    {
      dataIndex: "rescat",
      key: "rescat",
      title: "Rescat"
    },
    {
      dataIndex: "dataFetcherResult",
      key: "dataFetcherResult",
      title: "Cached Value",
      render: (text) => {
        const modifiedText = text == "null" ? <i>{text}</i> : text;
        return <span>{modifiedText}</span>;
      }
    },
    {
      dataIndex: "mongoValue",
      key: "mongoValue",
      title: "Mongo Value",
      render: (text, record: DataSourcePerformanceResult) => {
        const modifiedText = text == "null" ? <i>{text}</i> : text;
        if (record.mongoValueLastUpdated) {
          const utcDate = new Date(record.mongoValueLastUpdated);
          const localDate = utcDate.toLocaleString();
          return (
            <DateUpdatedWrapper>
              {modifiedText}{" "}
              <Tooltip title={localDate}>
                <Icon path={mdiCalendarClock} size={1.3} />
              </Tooltip>
            </DateUpdatedWrapper>
          );
        }
        return <span>{modifiedText}</span>;
      }
    }
  ];

  const products = Array.from(
    new Set(
      categories
        .filter((x) => x.name === "Performance")[0]
        .columns.map((c) => {
          const parts = c.property.split(".");
          if (parts.length < 3) return c.product;

          return parts[1];
        })
    )
  ).map((product) => {
    const key = product.replace("+", "");
    const prod = ALL_PRODUCT_TYPES[key];

    return {
      key: product,
      value: product,
      label: prod?.label ?? product
    };
  });

  const columns = categories
    .filter((x) => x.name === "Performance")[0]
    .columns.filter((c) => c.product === "Oil")
    .map((column) => ({
      key: column.property.replace(`Performance.Oil.`, ""),
      value: column.property.replace(`Performance.Oil.`, ""),
      label: column.title
    }));

  return (
    <GroupByProvider>
      <p>
        The Performance Data Analyzer allows for comparing the various performance data
        sources, including forecast sources. Its also allows comparing stored data vs
        cached data.
      </p>
      <SettingsContainer>
        <SettingWrapper>
          <SettingTitle>Product</SettingTitle>
          <SettingInputWrapper>
            <StyledSelect
              value={product}
              onSelect={setProduct}
              options={products}
              showSearch={true}
            />
          </SettingInputWrapper>
        </SettingWrapper>
        <SettingWrapper>
          <SettingTitle>Performance Column</SettingTitle>
          <SettingInputWrapper>
            <StyledSelect value={column} onSelect={setColumn} options={columns} />
          </SettingInputWrapper>
        </SettingWrapper>
        <SettingWrapper>
          <SettingTitle>UWI</SettingTitle>
          <SettingInputWrapper>
            <Input
              ref={uwiInputRef}
              value={uwi}
              onChange={handleUwiChange}
              placeholder="UWI to analyze data for"
            />
          </SettingInputWrapper>
        </SettingWrapper>
      </SettingsContainer>
      <ActionsContainer>
        <Button
          type="primary"
          disabled={!uwi}
          loading={isLoading && !isError}
          onClick={() => startAnalysis()}>
          {isLoading && !isError ? "Analyzing" : "Start Analysis"}
        </Button>
      </ActionsContainer>
      <ResultsContainer>
        {data && !analyzerError && (
          <ResultsTable>
            <Table
              dataSource={data?.dataSourceResults}
              columns={tableColumns}
              rowKey="key"
              pagination={false}
              loading={isLoading}
            />
          </ResultsTable>
        )}
        {analyzerError && (
          <ErrorContainer>
            <ErrorMessage>
              <Icon path={mdiAlert} size={1} /> {analyzerError}
              <ErrorDetailsToggle
                onClick={() => setShowAnalyzerErrorDetails(!showAnalyzerErrorDetails)}>
                {showAnalyzerErrorDetails ? "hide details" : "show details"}
              </ErrorDetailsToggle>
            </ErrorMessage>
            {showAnalyzerErrorDetails && (
              <ErrorMessageDetails>{analyzerErrorDetails}</ErrorMessageDetails>
            )}
          </ErrorContainer>
        )}
      </ResultsContainer>
    </GroupByProvider>
  );
};

export default PerformanceDataAnalyzer;

const SettingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const SettingWrapper = styled.div`
  width: 100%;
`;

const SettingTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const SettingInputWrapper = styled.div`
  margin-top: 5px;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
  padding-top: 20px;
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ResultsTable = styled.div`
  width: 100%;
`;

const ErrorContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  border: 1px solid var(--color-danger);
  border-radius: 5px;
  padding: 3px 15px;
  font-weight: var(--fontWeightMedium);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--color-danger);
`;

const ErrorDetailsToggle = styled.span`
  color: #a2aaad;
  cursor: pointer;
`;

const ErrorMessageDetails = styled.div`
  width: 100%;
  color: var(--color-text);
  font-family: var(--fontMono);
  text-align: left;
`;

const DateUpdatedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    color: #a2aaad;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
