// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { memo } from "react";

import { mdiInformationVariant } from "@mdi/js";

import { ToolbarButton } from "../components";
import { updateXDASettings, useVisState } from "../context";

const XdaValueInfoToggle = () => {
  const [{ xda }, visDispatch] = useVisState();
  const { showXdaValueInfo } = xda.settings;
  const handleToggle = () => (value) => {
    const nextSettings = { ...xda.settings, showXdaValueInfo: value };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    // "Key" will help react to re-render the button and correct the state of tooltip
    // Without re-render, tooltip will remember the state of mouse-over -> tooltip not closed even tho the window was already expanded/collapsed
    <ToolbarButton
      key={"XdaValueInfo" + showXdaValueInfo}
      active={showXdaValueInfo}
      activeIcon={<Icon path={mdiInformationVariant} size={1} />}
      icon={<Icon path={mdiInformationVariant} size={1} />}
      tooltipText="Toggle Xda Value Info"
      onToggle={handleToggle()}
    />
  );
};

export default memo(XdaValueInfoToggle);
