import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Alert, Button, Divider, Input, Select, Slider, Switch } from "antd";
import { scaleByOptions } from "constants/visualization.constants";
import _debounce from "lodash/debounce";
import { setVisSettings } from "store/features";
import styled from "styled-components/macro";

import { useDebounce } from "hooks";
import usePlayTo3DDataSources from "hooks/usePlayTo3DDataSources";
import usePlayTo3DFields from "hooks/usePlayTo3DFields";

import InputCollectionHeader from "components/base/InputCollectionHeader";

import useUserModules from "../../hooks/useUserModules";
import getGeoMapLayers from "../geo-map/hooks/getGeoMapLayers";
import XdaDataPicker from "./XdaDataPicker";
import { updateXDASettings, useVisState } from "./context";
import { IpdbBin, IpdbColors, XDA_RADIUS_TYPES } from "./context/types";

// constants for radius dimensions
const DIMENSION_MIN = 0;
const DIMENSION_MAX = 1000;

interface XDAViewerOptionsProps {
  binUpdateErrorMessage: string;
}

const XDAViewerOptions = (props: XDAViewerOptionsProps) => {
  const dispatch = useDispatch();
  const { has3dGeoModel } = useUserModules();
  const [{ xda }, visDispatch] = useVisState();
  const {
    bin,
    completionLength,
    downHeight,
    hangWellsToTop,
    ipdbColor,
    ipdbField,
    ipdbIsDefault,
    ipdbSource,
    leftWidth,
    ipdbOpacity,
    topsModelSource,
    reverseColor,
    rightWidth,
    scaleByOption,
    scaleByValue,
    showCompletion,
    showData,
    showGrid,
    showIpdb,
    showIpdbLegend,
    showTooltip,
    upHeight,
    showAllTops,
    showOverlap,
    widthScaled
  } = xda.settings;

  const { data: fields, refetch: refetchPlayFields } = usePlayTo3DFields(ipdbSource);
  const { data: dataSources, refetch: refetchDataSources } = usePlayTo3DDataSources();
  const { data: mapLayers } = getGeoMapLayers();

  const [binSetting, setBinSetting] = useState<IpdbBin>(null);

  const debouncedUpHeight = useDebounce(upHeight, 1200);
  const debouncedDownHeight = useDebounce(downHeight, 1200);
  const debouncedLeftWidth = useDebounce(leftWidth, 1200);
  const debouncedRightWidth = useDebounce(rightWidth, 1200);

  const mcdanielGroup = [
    {
      label: "McDaniel",
      options: [{ label: "McDaniel", value: "McDaniel" }]
    }
  ];
  const [topsModelSources, setTopsModelSources] = useState(mcdanielGroup);

  useEffect(() => {
    const orgGroups = {
      label: "Organization",
      options: []
    };
    if (mapLayers?.length > 0) {
      for (const item of mapLayers) {
        if (orgGroups.options.findIndex((o) => o.value === item.group) === -1) {
          orgGroups.options.push({ label: item.group, value: item.group });
        }
      }
    }
    setTopsModelSources([...mcdanielGroup, orgGroups]);
  }, [mapLayers]);

  // sync option changes with context
  const handleSettingChange = (key) => (value) => {
    let updatedValue = value;
    if (key === "downHeight" || key === "rightWidth") {
      //Convert value to 32-bit unsigned integer if handlesSettingChange is wrapped in inputWrapper. Math.max is used to ensure does not go below min if input inputted via typing
      updatedValue = Math.max(DIMENSION_MIN, value * 1);
    } else if (key === "scaleByValue" || key === "completionLength") {
      //Convert value to 32-bit unsigned integer if handlesSettingChange is wrapped in inputWrapper. Math.min to ensure does not go above max if input is inputted via typing
      updatedValue = Math.min(DIMENSION_MAX, value * 1);
    } else if (key === "bin") {
      updatedValue = {
        lessThan: parseFloat(value.lessThan),
        binSize: parseFloat(value.binSize),
        greaterThan: parseFloat(value.greaterThan)
      };
    }

    const nextSettings = { ...xda.settings, [key]: updatedValue };
    updateXDASettings(visDispatch, nextSettings);
  };

  const debouncedUpdateSetting = useRef(
    _debounce((fn) => {
      fn();
    }, 500)
  ).current;

  useEffect(() => {
    refetchPlayFields();
    // Unset field since data source changed
    updateXDASettings(visDispatch, { ...xda.settings, ipdbField: undefined });
  }, [ipdbSource]);

  useEffect(() => {
    if (fields?.length) {
      // Set init value for ipdbField to either porosity or first item.
      const initValue = fields.find((field) => field.name === "porosity") || fields[0];

      const nextSettings = { ...xda.settings, ipdbField: initValue.displayName };
      updateXDASettings(visDispatch, nextSettings);
    }
  }, [fields]);

  useEffect(() => {
    refetchDataSources();
  }, []);

  // sync width/height updates with store/app/visSettings
  useEffect(() => {
    dispatch(
      setVisSettings({
        height: debouncedUpHeight + debouncedDownHeight,
        width: debouncedLeftWidth + debouncedRightWidth
      })
    );
  }, [
    debouncedLeftWidth,
    debouncedRightWidth,
    debouncedDownHeight,
    debouncedUpHeight,
    dispatch
  ]);

  useEffect(() => {
    setBinSetting({
      lessThan: bin?.lessThan,
      binSize: bin?.binSize,
      greaterThan: bin?.greaterThan
    });
  }, [bin]);

  function updateBin(newBin: IpdbBin) {
    debouncedUpdateSetting(() => handleSettingChange("bin")(newBin));
    if (ipdbIsDefault) {
      debouncedUpdateSetting(() => handleSettingChange("ipdbIsDefault")(false));
    }
  }

  const handleTopChange = (value) => {
    //Convert value to 32-bit unsigned integer as handleTopChange is wrapped in inputWrapper. Math.max to ensure does not go below min if input is inputted via typing
    const updatedValue = Math.max(DIMENSION_MIN, value * 1);

    const nextSettings = {
      ...xda.settings,
      upHeight: updatedValue,
      downHeight: updatedValue
    };

    updateXDASettings(visDispatch, nextSettings);
  };

  const handleLeftChange = (value) => {
    //Convert value to 32-bit unsigned integer as handleLeftChange is wrapped in inputWrapper. Math.max to ensure does not go below min if input is inputted via typing
    const updatedValue = Math.max(DIMENSION_MIN, value * 1);

    const nextSettings = {
      ...xda.settings,
      leftWidth: updatedValue,
      rightWidth: updatedValue
    };

    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <Options>
      <OptionItem>
        <Label>Show Grid</Label>
        <Switch
          size="small"
          checked={showGrid}
          onChange={handleSettingChange("showGrid")}
        />
      </OptionItem>
      <OptionItem>
        <Label>Scale Width</Label>
        <Slider
          min={1}
          max={10}
          value={widthScaled}
          onChange={handleSettingChange("widthScaled")}
        />
      </OptionItem>
      <OptionItem>
        <Label>Show Tooltip</Label>
        <Switch
          size="small"
          checked={showTooltip}
          onChange={handleSettingChange("showTooltip")}
        />
      </OptionItem>
      <OptionItem>
        <Label>Hang To Top Zone</Label>
        <Switch
          size="small"
          checked={hangWellsToTop}
          onChange={handleSettingChange("hangWellsToTop")}
        />
      </OptionItem>
      <OptionItem>
        <Label>Tops Model Source</Label>
        <Select
          mode="multiple"
          size="small"
          defaultValue={["McDaniel"]}
          value={topsModelSource}
          options={topsModelSources}
          onChange={handleSettingChange("topsModelSource")}
        />
      </OptionItem>
      <OptionItem>
        <Label>Show All Tops</Label>
        <Switch
          size="small"
          checked={showAllTops}
          onChange={handleSettingChange("showAllTops")}
        />
      </OptionItem>
      <OptionItem>
        <Label>Show Overlap</Label>
        <Switch
          size="small"
          checked={showOverlap}
          onChange={handleSettingChange("showOverlap")}
        />
      </OptionItem>
      {has3dGeoModel && (
        <>
          <Divider />
          <OptionItem>
            <Label>Show 3D Geo Model</Label>
            <Switch
              size="small"
              checked={showIpdb}
              onChange={handleSettingChange("showIpdb")}
            />
          </OptionItem>
          <OptionItem>
            <Label>Data Source</Label>
            <Select
              size="small"
              value={ipdbSource}
              options={dataSources?.map((source) => ({
                label: source,
                value: source
              }))}
              onChange={handleSettingChange("ipdbSource")}
            />
          </OptionItem>
          <OptionItem>
            <Label>Field</Label>
            <Select
              size="small"
              value={ipdbField}
              options={fields?.map((field) => ({
                label: field.displayName,
                value: field.displayName,
                title: field.tooltip ?? field.displayName
              }))}
              onChange={(value) => {
                const field = fields.find((field) => field.displayName === value);
                const hasUnit = field?.unit;
                updateXDASettings(visDispatch, {
                  ...xda.settings,
                  ipdbField: value,
                  ipdbUnit: `${hasUnit ? ` (${field?.unit})` : ""}`,
                  ipdbIsDefault: true
                });
              }}
            />
          </OptionItem>
          <OptionItem>
            <Label>Colour Palette</Label>
            <Switch
              size="small"
              unCheckedChildren={"Reverse"}
              checkedChildren={"Reverse"}
              checked={reverseColor}
              onChange={handleSettingChange("reverseColor")}
            />
            <Select
              size="small"
              value={ipdbColor}
              options={IpdbColors.map((field) => ({
                label: field,
                value: field
              }))}
              onChange={handleSettingChange("ipdbColor")}
            />
          </OptionItem>
          <OptionItem>
            <Label>Unit</Label>
            {fields?.find((field) => field.displayName === ipdbField)?.unit}
          </OptionItem>
          <OptionItem>
            <Label>Bin</Label>
            <BinContainer>
              <BinItem>
                <BinInputLabel>Less Than</BinInputLabel>
                <Input
                  type="number"
                  onChange={(evt) => {
                    // Cast to unknown first so that the user can input any decimal value.
                    // Problem was with the decimal place and values such as 1.0, parsing it directly as a float/number will be 1.
                    // So the user could never enter a value like 1.01.
                    const inputValue = evt.target.value as unknown as number;
                    updateXDASettings(visDispatch, {
                      ...xda.settings,
                      ipdbIsDefault: false
                    });
                    setBinSetting((prevBinSettings) => ({
                      ...prevBinSettings,
                      lessThan: inputValue
                    }));
                  }}
                  value={binSetting?.lessThan}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      updateBin(binSetting);
                    }
                  }}
                />
              </BinItem>
              <BinItem>
                <BinInputLabel>Bin Size</BinInputLabel>
                <Input
                  type="number"
                  min={0}
                  onChange={(evt) => {
                    const inputValue = evt.target.value as unknown as number;
                    updateXDASettings(visDispatch, {
                      ...xda.settings,
                      ipdbIsDefault: false
                    });
                    setBinSetting((prevBinSettings) => ({
                      ...prevBinSettings,
                      binSize: inputValue
                    }));
                  }}
                  value={binSetting?.binSize}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      updateBin(binSetting);
                    }
                  }}
                />
              </BinItem>
              <BinItem>
                <BinInputLabel>Greater Than</BinInputLabel>
                <Input
                  type="number"
                  onChange={(evt) => {
                    const inputValue = evt.target.value as unknown as number;
                    updateXDASettings(visDispatch, {
                      ...xda.settings,
                      ipdbIsDefault: false
                    });
                    setBinSetting((prevBinSettings) => ({
                      ...prevBinSettings,
                      greaterThan: inputValue
                    }));
                  }}
                  value={binSetting?.greaterThan}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      updateBin(binSetting);
                    }
                  }}
                />
              </BinItem>
            </BinContainer>
          </OptionItem>
          <OptionItem>
            <BinUpdateButton
              onClick={() => {
                updateBin(binSetting);
              }}>
              Update Bin Settings
            </BinUpdateButton>
          </OptionItem>
          {props.binUpdateErrorMessage && (
            <Alert message={props.binUpdateErrorMessage} type="error" />
          )}
          <OptionItem>
            <Label>Legend</Label>
            <Switch
              size="small"
              checked={showIpdbLegend}
              onChange={handleSettingChange("showIpdbLegend")}
            />
          </OptionItem>
          <OptionItem>
            <Label>Legend Opacity</Label>
            <InputWrapper
              min={0}
              max={1}
              step={0.1}
              size="small"
              type="number"
              value={ipdbOpacity}
              onChange={(e) => handleSettingChange("ipdbOpacity")(e.target.value)}
            />
          </OptionItem>
          <Divider />
        </>
      )}
      <OptionItem>
        <Label>Show Data</Label>
        <Switch
          size="small"
          checked={showData}
          onChange={handleSettingChange("showData")}
        />
      </OptionItem>
      <XdaDataPicker
        onChange={(fields) => {
          const nextSettings = { ...xda.settings, dataFields: fields };
          updateXDASettings(visDispatch, nextSettings);
        }}
      />
      <InputCollectionHeader header={"Radius Dimensions"}></InputCollectionHeader>
      <OptionItem>
        <Label>Select Radius</Label>
        <Select
          size="small"
          value={xda.settings.selectedRadius}
          onChange={(value) => {
            handleSettingChange("selectedRadius")(value);
          }}>
          <option value={XDA_RADIUS_TYPES.Gross}>Gross</option>
          <option value={XDA_RADIUS_TYPES.SweetSpot}>Sweet Spot</option>
          <option value={XDA_RADIUS_TYPES.LandingZone}>Landing Zone</option>
          <option value={XDA_RADIUS_TYPES.Custom}>Custom</option>
        </Select>
      </OptionItem>

      <OptionItem>
        <Label>Up (m)</Label>
        <OptionItemRightGroup>
          <InputWrapper
            disabled={xda.settings.selectedRadius != XDA_RADIUS_TYPES.Custom}
            min={DIMENSION_MIN}
            onChange={(e) => handleTopChange(e.target.value)}
            size="small"
            step={1}
            type="number"
            value={upHeight}
          />
        </OptionItemRightGroup>
      </OptionItem>
      <OptionItem>
        <Label>Down (m)</Label>
        <InputWrapper
          disabled={xda.settings.selectedRadius != XDA_RADIUS_TYPES.Custom}
          min={DIMENSION_MIN}
          onChange={(e) => handleSettingChange("downHeight")(e.target.value)}
          size="small"
          step={1}
          type={"number"}
          value={downHeight}
        />
      </OptionItem>
      <SmallPadding />
      <OptionItem>
        <Label>Left (m)</Label>
        <OptionItemRightGroup>
          <InputWrapper
            disabled={xda.settings.selectedRadius != XDA_RADIUS_TYPES.Custom}
            min={DIMENSION_MIN}
            onChange={(e) => handleLeftChange(e.target.value)}
            size="small"
            step={10}
            type={"number"}
            value={leftWidth}
          />
        </OptionItemRightGroup>
      </OptionItem>
      <OptionItem>
        <Label>Right (m)</Label>
        <InputWrapper
          disabled={xda.settings.selectedRadius != XDA_RADIUS_TYPES.Custom}
          min={DIMENSION_MIN}
          onChange={(e) => handleSettingChange("rightWidth")(e.target.value)}
          size="small"
          step={10}
          type={"number"}
          value={rightWidth}
        />
      </OptionItem>
      <SmallPadding />
      <OptionItem>
        <Label>Show Completion</Label>
        <Switch
          size="small"
          checked={showCompletion}
          onChange={handleSettingChange("showCompletion")}
        />
      </OptionItem>
      {showCompletion && (
        <>
          <OptionItem>
            <Label>Scale by</Label>
            <Select
              style={{ width: "220px" }}
              size="small"
              value={scaleByOption}
              options={scaleByOptions}
              defaultValue={scaleByOptions[0]}
              onChange={handleSettingChange("scaleByOption")}
            />
          </OptionItem>
          <OptionItem>
            <Label>Value (t)</Label>
            <InputWrapper
              type={"number"}
              max={DIMENSION_MAX}
              step={10}
              size="small"
              value={scaleByValue}
              onChange={(e) => handleSettingChange("scaleByValue")(e.target.value)}
            />
          </OptionItem>
          <OptionItem>
            <Label>Length (m)</Label>
            <InputWrapper
              type={"number"}
              max={DIMENSION_MAX}
              step={10}
              size="small"
              value={completionLength}
              onChange={(e) => handleSettingChange("completionLength")(e.target.value)}
            />
          </OptionItem>
        </>
      )}
    </Options>
  );
};

export default XDAViewerOptions;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 600px;
  height: 70vh;
  overflow-y: auto;
  padding-right: 12px;

  width: 400px;

  .ant-card-body {
    padding: 5px;
    padding-right: 10px;

    height: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const Label = styled.label`
  min-width: 120px;
`;

const InputWrapper = styled(Input)`
  max-width: 100px;
  border-radius: var(--border-radius);
`;
const BinContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
`;

const BinInputLabel = styled.span`
  font-size: 1.2rem;
`;

const BinItem = styled.div`
  max-width: 76px;
`;

const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;

  .ant-slider {
    width: 80px;
  }

  .ant-input-number {
    max-width: 130px;
    width: 130px;
  }

  .ant-select {
    width: 130px;
  }

  .ant-btn {
    border-radius: 5px;
  }

  .ant-btn-circle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-btn:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }

  padding: 2px 0;
`;

const OptionItemRightGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 5px;

  .ant-slider {
    width: 80px;
  }

  .ant-input-number {
    max-width: 130px;
    width: 130px;
  }

  .ant-select {
    width: 130px;
  }

  .ant-btn {
    border-radius: 5px;
  }

  .ant-btn-circle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-btn:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }

  padding: 2px 0;
`;

const SmallPadding = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const BinUpdateButton = styled(Button)`
  border-radius: 20px;
  width: 200px;
  background-color: var(--color-primary);
  height: 30px;
  width: 100%;

  font-weight: 500;
`;
