import { memo } from "react";

import StraightenIcon from "@material-ui/icons/Straighten";

import { ToolbarButton } from "../components";
import { updateXDASettings, useVisState } from "../context";

const MeasurementToggleButton = () => {
  const [{ xda }, visDispatch] = useVisState();
  const { showMeasurement } = xda.settings;
  const handleToggle = () => (value) => {
    const nextSettings = { ...xda.settings, showMeasurement: value };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    // "Key" will help react to re-render the button and correct the state of tooltip
    // Without re-render, tooltip will remember the state of mouse-over -> tooltip not closed even tho the window was already expanded/collapsed
    <ToolbarButton
      key={"FullscreenToggleButton" + showMeasurement}
      active={showMeasurement}
      activeIcon={<StraightenIcon />}
      icon={<StraightenIcon />}
      tooltipText="Toggle Measurement Tool"
      onToggle={handleToggle()}
    />
  );
};

export default memo(MeasurementToggleButton);
