import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { ArpSegment } from "models/UserArpsModel";

import { SegmentTemplate } from "../models/SegmentTemplate";
import {
  arpsWasm,
  oneSegmentTerminalDecline,
  threeSegmentTerminalDecline,
  threeSegmentTerminalDeclineWithRampUp,
  twoSegmentTerminalDecline,
  twoSegmentTerminalDeclineWithRampUp
} from "../utils/UpdateSegment";
import { getTypewellTemplateFields } from "../utils/arpsUtils";

export default function useSegmentTemplates(): SegmentTemplate[] {
  const typeWellSettings = useSelector(
    (state: RootState) => state.userSetting.typeWellSettings
  );

  const today = new Date();

  const getDefaultTypeWell = (product) => {
    return typeWellSettings.settings.find(
      (s) => s.product === product || (s.product == "CONDENSATE" && product == "COND.")
    );
  };

  const initialSegment0 = (product, startDate) => {
    const defaultTypeWell = getDefaultTypeWell(product);
    startDate = !startDate
      ? new Date(today.getUTCFullYear(), today.getUTCMonth(), 1).toISOString()
      : startDate;
    const endDate = getRampUpEndDate(startDate, defaultTypeWell.values.tramp);

    return {
      product,
      qi: defaultTypeWell.values.Q0,
      b: 0,
      qf: defaultTypeWell.values.Qi,
      df: -1, //TODO: calculate the correct df based on Q0 and Qi
      di: -1, //TODO: calculate the correct di based on Q0 and Qi
      startDate: startDate,
      segmentIndex: 1,
      trendCum: 0,
      endDate: endDate
    };
  };

  const initialSegment1 = (product, startDate) => {
    const defaultTypeWell = getDefaultTypeWell(product);

    startDate = !startDate
      ? new Date(today.getUTCFullYear(), today.getUTCMonth(), 1).toISOString()
      : startDate;
    const endDate = new Date(today.getUTCFullYear(), today.getUTCMonth(), 1);

    return {
      product,
      qi: defaultTypeWell.values.Qi,
      b: defaultTypeWell.values.Bhyp,
      qf: defaultTypeWell.values.Qf,
      df: arpsWasm.getNominalDeclineAtRate(
        defaultTypeWell.values.Di,
        defaultTypeWell.values.Qi,
        defaultTypeWell.values.Bhyp,
        defaultTypeWell.values.Qf
      ),
      di: defaultTypeWell.values.Di,
      startDate: startDate,
      segmentIndex: 1,
      trendCum: 0,
      endDate: endDate.toISOString()
    };
  };

  const getDateAtDay = (date: Date, days: number): Date => {
    const endDate = new Date(date.getTime());
    endDate.setDate(endDate.getDate() + days);
    return endDate;
  };

  const getRampUpEndDate = (rampUpStartDate: string, tramp: number): string => {
    const startDateObj = new Date(Date.parse(rampUpStartDate));
    startDateObj.setMonth(startDateObj.getMonth() + tramp);
    return startDateObj.toISOString();
  };

  function updateDfFromQt(segment: ArpSegment) {
    segment.df = arpsWasm.getNominalDeclineAtRate(
      segment.di,
      segment.qi,
      segment.b,
      segment.qf
    );
  }

  function updateEndDates(s1: ArpSegment, s1StartDate: Date, s2?: ArpSegment) {
    let s1DayDelta = arpsWasm.getDayAtRate(s1.qi, s1.di, s1.b, s1.qf);
    const s1EndDate = new Date(s1StartDate.getTime());
    // s1DayDelta can be invalid date due to incorrect decline settings, set to zero to catch this.
    if (!s1DayDelta || !isFinite(s1DayDelta)) {
      s1DayDelta = 0;
    }
    s1EndDate.setDate(s1EndDate.getDate() + s1DayDelta);
    s1.endDate = s1EndDate.toISOString();

    updateDfFromQt(s1);
    if (s2) {
      s2.startDate = s1.endDate;
      //decline linked
      s2.di = s1.df;
      updateDfFromQt(s2);
    }
  }

  return useMemo(
    () => [
      {
        name: "One Seg.",
        productCols: [],
        productData: [],
        numberOfSegments: 1,
        rowHeaders: ["Start Date", "Qi", "Di", "B", "Qf", "EUR"],
        displayHeaders: ["Start Date", "Q.i", "D.i", "B", "Q.f", "EUR"],
        update: oneSegmentTerminalDecline,
        initialData: (product, startDate) => {
          const seg1 = initialSegment1(product, startDate);
          updateEndDates(seg1, new Date(seg1.startDate));
          return [seg1];
        },
        extendArps: (productArps: ArpSegment[]) => {
          const product = productArps[0].product;
          const defaultTypeWell = getDefaultTypeWell(product);

          const currentValues = getTypewellTemplateFields(
            productArps,
            null,
            defaultTypeWell
          );

          const bVal = currentValues.B ?? defaultTypeWell.values.Bhyp;

          const seg1 = {
            product,
            qi: currentValues.Qi,
            b: bVal,
            qf: parseFloat(currentValues.Qf.toString()),
            df: arpsWasm.getNominalDeclineAtRate(
              currentValues.Di,
              currentValues.Qi,
              bVal,
              parseFloat(currentValues.Qf.toString())
            ),
            di: currentValues.Di,
            startDate: currentValues["Start Date"],
            segmentIndex: 1,
            trendCum: 0,
            endDate: currentValues["Start Date"]
          };

          return [seg1];
        }
      },
      {
        name: "Two Seg. Hyperbolic",
        productCols: [],
        productData: [],
        numberOfSegments: 2,
        rowHeaders: ["Start Date", "Qi", "Di", "Bhyp", "Df", "Bf", "Qf", "EUR"],
        displayHeaders: ["Start Date", "Q.i", "D.i", "B.hyp", "D.f", "B.f", "Q.f", "EUR"],
        update: twoSegmentTerminalDecline,
        initialData: (product, startDate) => {
          const defaultTypeWell = getDefaultTypeWell(product);

          const s1 = initialSegment1(product, startDate);

          s1.df = defaultTypeWell.values.Df;
          const qf1 = arpsWasm.getRateAtNominal(s1.di, s1.df, s1.qi, s1.b);

          s1.qf = qf1;

          const s2 = {
            product,
            qi: s1.qf,
            b: defaultTypeWell.values.Bf,
            qf: defaultTypeWell.values.Qf,
            df: s1.df,
            di: s1.df,
            startDate: new Date().toISOString(),
            segmentIndex: 2,
            trendCum: 0,
            endDate: new Date().toISOString()
          };
          updateEndDates(s1, new Date(s1.startDate), s2);
          return [s1, s2];
        },
        extendArps: (productArps: ArpSegment[]) => {
          const product = productArps[0].product;
          const defaultTypeWell = getDefaultTypeWell(product);

          const currentValues = getTypewellTemplateFields(
            productArps,
            null,
            defaultTypeWell
          );

          const seg1 = {
            product,
            qi: currentValues.Qi,
            b: currentValues.Bhyp ?? defaultTypeWell.values.Bhyp,
            qf: parseFloat(currentValues.Qf.toString()),
            df: currentValues.Df,
            di: currentValues.Di,
            startDate: currentValues["Start Date"],
            segmentIndex: 1,
            trendCum: 0,
            endDate: currentValues["Start Date"]
          };

          seg1.qf = arpsWasm.getRateAtNominal(seg1.di, seg1.df, seg1.qi, seg1.b);

          const seg2 = {
            product,
            qi: seg1.qf,
            b: currentValues.Bf ?? defaultTypeWell.values.Bf,
            qf: parseFloat(currentValues.Qf.toString()),
            df: seg1.df,
            di: seg1.df,
            startDate: seg1.endDate,
            segmentIndex: 2,
            trendCum: 0,
            endDate: new Date().toISOString()
          } as ArpSegment;

          return [seg1].concat([seg2]);
        }
      } as SegmentTemplate,
      {
        name: "Two Seg. Hyperbolic with Ramp-Up",
        productCols: [],
        productData: [],
        numberOfSegments: 3,
        rowHeaders: [
          "Start Date",
          "Q0",
          "tRamp",
          "Qi",
          "Di",
          "Bhyp",
          "Df",
          "Bf",
          "Qf",
          "EUR"
        ],
        displayHeaders: [
          "Start Date",
          "Q.0",
          "t.ramp",
          "Q.i",
          "D.i",
          "B.hyp",
          "D.f",
          "B.f",
          "Q.f",
          "EUR"
        ],
        update: twoSegmentTerminalDeclineWithRampUp,
        initialData: (product, startDate) => {
          const defaultTypeWell = getDefaultTypeWell(product);
          const s1 = initialSegment0(product, startDate);
          const s2StartDate = getRampUpEndDate(startDate, defaultTypeWell.values.tramp);
          const s2 = { ...initialSegment1(product, s2StartDate), segmentIndex: 2 };
          s2.df = defaultTypeWell.values.Df;
          const qf2 = arpsWasm.getRateAtNominal(s2.di, s2.df, s2.qi, s2.b);

          s2.qf = qf2;

          const s3 = {
            product,
            qi: s2.qf,
            b: defaultTypeWell.values.Bf,
            qf: defaultTypeWell.values.Qf,
            df: s2.df,
            di: s2.df,
            startDate: new Date().toISOString(),
            segmentIndex: 3,
            trendCum: 0,
            endDate: new Date().toISOString()
          };
          updateEndDates(s2, new Date(s2.startDate), s3);

          return [s1, s2, s3];
        },
        extendArps: (productArps: ArpSegment[]) => {
          const product = productArps[0].product;
          const defaultTypeWell = getDefaultTypeWell(product);

          const currentValues = getTypewellTemplateFields(
            productArps,
            null,
            defaultTypeWell
          );

          const startDate = new Date(currentValues["Start Date"]);
          const endDate = new Date(currentValues["Start Date"]);
          endDate.setMonth(
            endDate.getMonth() + (currentValues.tRamp ?? defaultTypeWell.values.tramp)
          );

          const seg1 = {
            product,
            qi: currentValues.Q0 ?? defaultTypeWell.values.Q0,
            b: 0,
            qf: currentValues.Qi,
            df: -1, //TODO: calculate the correct df based on Q0 and Qi
            di: -1, //TODO: calculate the correct di based on Q0 and Qi
            startDate: startDate.toISOString(),
            segmentIndex: 1,
            trendCum: 0,
            endDate: endDate.toISOString()
          };

          const seg2 = {
            product,
            qi: seg1.qf,
            b: currentValues.Bhyp ?? defaultTypeWell.values.Bhyp,
            qf: seg1.qf,
            df: currentValues.Df,
            di: currentValues.Di,
            startDate: seg1.endDate,
            segmentIndex: 2,
            trendCum: 0,
            endDate: new Date().toISOString()
          };

          seg2.qf = arpsWasm.getRateAtNominal(seg2.di, seg2.df, seg2.qi, seg2.b);

          const seg3 = {
            product,
            qi: seg2.qf,
            b: currentValues.Bf ?? defaultTypeWell.values.Bf,
            qf: parseFloat(currentValues.Qf.toString()),
            df: seg2.df,
            di: seg2.df,
            startDate: seg2.endDate,
            segmentIndex: 3,
            trendCum: 0,
            endDate: new Date().toISOString()
          } as ArpSegment;

          return [seg1].concat([seg2, seg3]);
        }
      } as SegmentTemplate,
      {
        name: "Three Seg. Transient",
        productCols: [],
        productData: [],
        numberOfSegments: 3,
        rowHeaders: [
          "Start Date",
          "Qi",
          "Di",
          "Btrans",
          "tTrans",
          "Bhyp",
          "Df",
          "Bf",
          "Qf",
          "EUR"
        ],
        displayHeaders: [
          "Start Date",
          "Q.i",
          "D.i",
          "B.trans",
          "t.trans",
          "B.hyp",
          "D.f",
          "B.f",
          "Q.f",
          "EUR"
        ],
        update: threeSegmentTerminalDecline,
        initialData: (product, startDate) => {
          const defaultTypeWell = getDefaultTypeWell(product);

          const s1 = {
            ...initialSegment1(product, startDate),
            switchMonth: defaultTypeWell.values.ttrans,
            b: defaultTypeWell.values.Btrans
          };

          const qf1 = arpsWasm.getRateAtDay(s1.qi, s1.di, s1.b, s1.switchMonth * 30.4375);
          s1.qf = qf1;
          s1.df = arpsWasm.getNominalDeclineAtRate(s1.di, s1.qi, s1.b, qf1);

          const endDate = new Date(s1.startDate);
          endDate.setDate(endDate.getDate() + s1.switchMonth * 30.4375);
          s1.endDate = endDate.toISOString();

          const s2 = {
            product,
            qi: s1.qf,
            b: defaultTypeWell.values.Bhyp,
            qf: 100.0,
            df: defaultTypeWell.values.Df,
            di: s1.df,
            startDate: s1.endDate,
            segmentIndex: 2,
            trendCum: 0,
            endDate: new Date().toISOString()
          };

          s2.qf = arpsWasm.getRateAtNominal(s2.di, s2.df, s2.qi, s2.b);

          s2.endDate = getDateAtDay(
            new Date(Date.parse(s2.startDate)),
            arpsWasm.getDayAtRate(s2.qi, s2.di, s2.b, s2.qf)
          ).toISOString();

          const s3 = {
            product,
            qi: s2.qf,
            b: defaultTypeWell.values.Bf,
            qf: defaultTypeWell.values.Qf,
            df: s2.df,
            di: s2.df,
            startDate: s2.endDate,
            segmentIndex: 3,
            trendCum: 0,
            timeCalculated: true,
            endDate: new Date().toISOString()
          };
          updateEndDates(s1, new Date(s1.startDate), s2);
          return [s1, s2, s3];
        },
        extendArps: (productArps: ArpSegment[]) => {
          const product = productArps[0].product;
          const defaultTypeWell = getDefaultTypeWell(product);

          const currentValues = getTypewellTemplateFields(
            productArps,
            null,
            defaultTypeWell
          );

          const bTrans = currentValues.Btrans ?? defaultTypeWell.values.Btrans;
          const tTrans = currentValues.tTrans ?? defaultTypeWell.values.ttrans;

          const seg1 = {
            product,
            qi: currentValues.Qi,
            b: bTrans,
            qf: arpsWasm.getRateAtDay(
              currentValues.Qi,
              currentValues.Di,
              bTrans,
              tTrans * 30.4375
            ),
            df: currentValues.Di,
            di: currentValues.Di,
            startDate: currentValues["Start Date"],
            segmentIndex: 1,
            trendCum: 0,
            endDate: currentValues["Start Date"]
          };

          seg1.df = arpsWasm.getNominalDeclineAtRate(seg1.di, seg1.qi, seg1.b, seg1.qf);

          const endDate = new Date(seg1.startDate);
          endDate.setDate(endDate.getDate() + tTrans * 30.4375);
          seg1.endDate = endDate.toISOString();

          const seg2 = {
            product,
            qi: seg1.qf,
            b: currentValues.Bhyp ?? defaultTypeWell.values.Bhyp,
            qf: 100.0,
            df: currentValues.Df,
            di: seg1.df,
            startDate: seg1.endDate,
            segmentIndex: 2,
            trendCum: 0,
            endDate: new Date().toISOString()
          };

          seg2.qf = arpsWasm.getRateAtNominal(seg2.di, seg2.df, seg2.qi, seg2.b);

          const seg3 = {
            product,
            qi: seg2.qf,
            b: currentValues.Bf ?? defaultTypeWell.values.Bf,
            qf: currentValues.Qf,
            df: seg2.df,
            di: seg2.df,
            startDate: seg2.endDate,
            segmentIndex: 3,
            trendCum: 0,
            endDate: new Date().toISOString()
          } as ArpSegment;
          return [seg1].concat([seg2, seg3]);
        }
      } as SegmentTemplate,
      {
        name: "Three Seg. Transient with Ramp-Up",
        productCols: [],
        productData: [],
        numberOfSegments: 4,
        rowHeaders: [
          "Start Date",
          "Q0",
          "tRamp",
          "Qi",
          "Di",
          "Btrans",
          "tTrans",
          "Bhyp",
          "Df",
          "Bf",
          "Qf",
          "EUR"
        ],
        displayHeaders: [
          "Start Date",
          "Q.0",
          "t.ramp",
          "Q.i",
          "D.i",
          "B.trans",
          "t.trans",
          "B.hyp",
          "D.f",
          "B.f",
          "Q.f",
          "EUR"
        ],
        update: threeSegmentTerminalDeclineWithRampUp,
        initialData: (product, startDate) => {
          const defaultTypeWell = getDefaultTypeWell(product);

          const s1 = initialSegment0(product, startDate);
          const s2StartDate = getRampUpEndDate(startDate, defaultTypeWell.values.tramp);
          const s2 = {
            ...initialSegment1(product, s2StartDate),
            switchMonth: defaultTypeWell.values.ttrans,
            b: defaultTypeWell.values.Btrans,
            segmentIndex: 2
          };
          const qf2 = arpsWasm.getRateAtDay(s2.qi, s2.di, s2.b, s2.switchMonth * 30.4375);
          s2.qf = qf2;
          s2.df = arpsWasm.getNominalDeclineAtRate(s2.di, s2.qi, s2.b, qf2);

          const endDate = new Date(s2.startDate);
          endDate.setDate(endDate.getDate() + s2.switchMonth * 30.4375);
          s2.endDate = endDate.toISOString();

          const s3 = {
            product,
            qi: s2.qf,
            b: defaultTypeWell.values.Bhyp,
            qf: 100.0,
            df: defaultTypeWell.values.Df,
            di: s2.df,
            startDate: s2.endDate,
            segmentIndex: 3,
            trendCum: 0,
            endDate: new Date().toISOString()
          };

          s3.endDate = getDateAtDay(
            new Date(Date.parse(s3.startDate)),
            arpsWasm.getDayAtRate(s3.qi, s3.di, s3.b, s3.qf)
          ).toISOString();

          const s4 = {
            product,
            qi: s3.qf,
            b: defaultTypeWell.values.Bf,
            qf: defaultTypeWell.values.Qf,
            df: s3.df,
            di: s3.df,
            startDate: s3.endDate,
            segmentIndex: 4,
            trendCum: 0,
            timeCalculated: true,
            endDate: new Date().toISOString()
          };
          s4.endDate = getDateAtDay(
            new Date(Date.parse(s4.startDate)),
            arpsWasm.getDayAtRate(s4.qi, s4.di, s4.b, s4.qf)
          ).toISOString();

          return [s1, s2, s3, s4];
        },
        extendArps: (productArps: ArpSegment[]) => {
          const product = productArps[0].product;
          const defaultTypeWell = getDefaultTypeWell(product);

          const currentValues = getTypewellTemplateFields(
            productArps,
            null,
            defaultTypeWell
          );

          const startDate = new Date(currentValues["Start Date"]);
          const endDate = new Date(currentValues["Start Date"]);
          endDate.setMonth(
            endDate.getMonth() + (currentValues.tRamp ?? defaultTypeWell.values.tramp)
          );

          const seg1 = {
            product,
            qi: currentValues.Q0 ?? defaultTypeWell.values.Q0,
            b: 0,
            qf: currentValues.Qi,
            df: -1, //TODO: calculate the correct df based on Q0 and Qi
            di: -1, //TODO: calculate the correct di based on Q0 and Qi
            startDate: startDate.toISOString(),
            segmentIndex: 1,
            trendCum: 0,
            endDate: endDate.toISOString()
          };

          const bTrans = currentValues.Btrans ?? defaultTypeWell.values.Btrans;
          const tTrans = currentValues.tTrans ?? defaultTypeWell.values.ttrans;

          const seg2 = {
            product,
            qi: seg1.qf,
            b: bTrans,
            qf: arpsWasm.getRateAtDay(
              currentValues.Qi,
              currentValues.Di,
              bTrans,
              tTrans * 30.4375
            ),
            df: currentValues.Df,
            di: currentValues.Di,
            startDate: seg1.endDate,
            segmentIndex: 2,
            trendCum: 0,
            endDate: new Date().toISOString()
          };

          seg2.df = arpsWasm.getNominalDeclineAtRate(seg2.di, seg2.qi, seg2.b, seg2.qf);

          const s2endDate = new Date(seg2.startDate);
          s2endDate.setDate(s2endDate.getDate() + tTrans * 30.4375);
          seg2.endDate = s2endDate.toISOString();

          const seg3 = {
            product,
            qi: seg2.qf,
            b: currentValues.Bhyp ?? defaultTypeWell.values.Bhyp,
            qf: 100.0,
            df: currentValues.Df,
            di: seg2.df,
            startDate: seg2.endDate,
            segmentIndex: 3,
            trendCum: 0,
            endDate: new Date().toISOString()
          };

          seg3.qf = arpsWasm.getRateAtNominal(seg3.di, seg3.df, seg3.qi, seg3.b);

          const seg4 = {
            product,
            qi: seg3.qf,
            b: currentValues.Bf ?? defaultTypeWell.values.Bf,
            qf: currentValues.Qf,
            df: seg3.df,
            di: seg3.df,
            startDate: seg3.endDate,
            segmentIndex: 4,
            trendCum: 0,
            endDate: new Date().toISOString()
          } as ArpSegment;

          return [seg1].concat([seg2, seg3, seg4]);
        }
      } as SegmentTemplate
    ],
    [typeWellSettings]
  );
}
