import { useState } from "react";

import SettingsIcon from "@material-ui/icons/Settings";
import { Popover } from "antd";

import XDAViewerOptions from "./XDAViewerOptions";
import { ToolbarButton } from "./components";

interface XDAViewerOptionsToggleProps {
  binUpdateErrorMessage: string;
}

const XDAViewerOptionsToggle = (props: XDAViewerOptionsToggleProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      trigger="click"
      placement="leftTop"
      content={<XDAViewerOptions binUpdateErrorMessage={props.binUpdateErrorMessage} />}
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ToolbarButton
        active={visible}
        icon={<SettingsIcon />}
        onToggle={(v) => setVisible(v)}
      />
    </Popover>
  );
};

export default XDAViewerOptionsToggle;
