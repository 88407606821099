import { useMemo } from "react";

import {
  ALL_PRODUCT_TYPES,
  ChartProduct,
  InjectionProduct,
  MidstreamProduct,
  SalesProduct
} from "constants/chart.constants";

import { EntityKind } from "../../../models/entityKind";
import { useGroupByState } from "../group-by.context";
import useSelectedCategory from "./useSelectedCategory";

interface ProductOption {
  value?: string;
  label: string;
  options?: ProductOption[];
}

const getOption = (item) => {
  const getSubscript = (c: string) => {
    //adds subscript to NGL_234 -> NGL₂₃₄
    if (c == "1") {
      return "\u2081";
    }
    if (c == "2") {
      return "\u2082";
    }
    if (c === "3") {
      return "\u2083";
    }
    if (c === "4") {
      return "\u2084";
    }
    throw "not a subscript character";
  };
  const parts = item.split("_");
  if (parts.length > 1) {
    const afterSubscript = parts[1].split(" ");
    return (
      parts[0] +
      afterSubscript[0].split("").map(getSubscript).join("") +
      (afterSubscript.length > 1 ? " " + afterSubscript.slice(1).join(" ") : "")
    );
  }
  if (item === "BOE") {
    return "Wellhead BOE";
  }
  return item;
};

// Ideally this should be removed in the future, but removing this currently
// breaks some of the product name mapping in the product selectors.
export const getMidstreamValue = (item: string) => {
  const midstreamValueMap = {
    [ALL_PRODUCT_TYPES.GasInlet.label]: ALL_PRODUCT_TYPES.Gas.key,
    [ALL_PRODUCT_TYPES.GasOutlet.label]: ALL_PRODUCT_TYPES.SalesGas.key,
    [ALL_PRODUCT_TYPES.C2YieldsInlet.label]: ALL_PRODUCT_TYPES.C2YieldsRaw.key,
    [ALL_PRODUCT_TYPES.C3YieldsInlet.label]: ALL_PRODUCT_TYPES.C3YieldsRaw.key,
    [ALL_PRODUCT_TYPES.C4YieldsInlet.label]: ALL_PRODUCT_TYPES.C4YieldsRaw.key,
    [ALL_PRODUCT_TYPES.C5YieldsInlet.label]: ALL_PRODUCT_TYPES.C5YieldsRaw.key,
    [ALL_PRODUCT_TYPES.OilCondC5YieldsInlet.label]:
      ALL_PRODUCT_TYPES.OilCondC5YieldsRaw.key,
    [ALL_PRODUCT_TYPES.LiquidsYieldsInlet.label]: ALL_PRODUCT_TYPES.LiquidsYieldsRaw.key,
    [ALL_PRODUCT_TYPES.NGLMixYieldsInlet.label]: ALL_PRODUCT_TYPES.NGLMixYieldsRaw.key,
    [ALL_PRODUCT_TYPES.NGL_234YieldsInlet.label]: ALL_PRODUCT_TYPES.NGL_234YieldsRaw.key,
    [ALL_PRODUCT_TYPES.C2YieldsOutlet.label]: ALL_PRODUCT_TYPES.C2YieldsSales.key,
    [ALL_PRODUCT_TYPES.C3YieldsOutlet.label]: ALL_PRODUCT_TYPES.C3YieldsSales.key,
    [ALL_PRODUCT_TYPES.C4YieldsOutlet.label]: ALL_PRODUCT_TYPES.C4YieldsSales.key,
    [ALL_PRODUCT_TYPES.C5YieldsOutlet.label]: ALL_PRODUCT_TYPES.C5YieldsSales.key,
    [ALL_PRODUCT_TYPES.LiquidsYieldsOutlet.label]:
      ALL_PRODUCT_TYPES.LiquidsYieldsSales.key,
    [ALL_PRODUCT_TYPES.OilCondC5YieldsOutlet.label]:
      ALL_PRODUCT_TYPES.OilCondC5YieldsSales.key,
    [ALL_PRODUCT_TYPES.NGL_234YieldsOutlet.label]:
      ALL_PRODUCT_TYPES.NGL_234YieldsSales.key,
    [ALL_PRODUCT_TYPES.NGLMixYieldsOutlet.label]: ALL_PRODUCT_TYPES.NGLMixYieldsSales.key,
    [ALL_PRODUCT_TYPES.SulphurYieldsInlet.label]:
      ALL_PRODUCT_TYPES.SulphurYieldsInlet.key,
    [ALL_PRODUCT_TYPES.SulphurYieldsOutlet.label]:
      ALL_PRODUCT_TYPES.SulphurYieldsOutlet.key
  };
  return midstreamValueMap[item] || item;
};

const useGroupedProductOptions = (): ProductOption[] => {
  const { productList } = useSelectedCategory();
  const { entityKind } = useGroupByState();

  return useMemo(() => {
    // Convert productList to a Set for constant time lookups
    const productSet = new Set(productList);

    const result: ProductOption[] = [];
    if (entityKind == EntityKind.Well) {
      ChartProduct.forEach((item) => {
        const options = [];
        item.items.forEach((product) => {
          if (productSet.has(product)) {
            options.push({
              value: product,
              label: getOption(product)
            });
          }
        });
        if (options?.length > 0) {
          result.push({
            label: `Wellhead ${item.name}`,
            options: options
          });
        }
      });
      SalesProduct.forEach((item) => {
        const options = [];
        item.items.forEach((product) => {
          if (productSet.has(product)) {
            options.push({ value: product, label: getOption(product) });
          }
        });
        if (options?.length > 0) {
          const prefix = item.name.includes("Yields") ? "Liquids" : "Sales";
          result.push({
            label: `${prefix} ${item.name}`,
            options: options
          });
        }
      });
      InjectionProduct.forEach((item) => {
        const options = [];
        item.items.forEach((product) => {
          if (productSet.has(product)) {
            options.push({ value: product, label: getOption(product) });
          }
        });
        if (options?.length) {
          result.push({
            label: `Injection ${item.name}`,
            options: options
          });
        }
      });
    }
    if (entityKind == EntityKind.Facility) {
      MidstreamProduct.forEach((item) => {
        const options = [];
        item.items.forEach((product) => {
          options.push({
            value: getMidstreamValue(product),
            label: getOption(product)
          });
        });
        if (options?.length > 0) {
          result.push({
            label: `${item.name}`,
            options: options
          });
        }
      });
    }
    return result;
  }, [productList]);
};

export default useGroupedProductOptions;
