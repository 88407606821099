export function getStartOfNextMonth(): Date {
  const currentDate = new Date();
  const day = currentDate.getDay();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth();
  if (day > 1) {
    month += 1;
  }
  if (month > 11) {
    month = 0;
    year += 1;
  }
  return new Date(year, month, 1);
}

export function numberOfMonthsBetweenDates(date1?: Date, date2?: Date): number {
  if (!date1 || !date2) {
    return 12;
  }
  // Calculate the time difference in milliseconds
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());

  // Convert milliseconds to days
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  // Convert days to months
  return Math.floor(daysDiff / 30);
}

export function numberOfMonthsAfterDate(date1?: Date, date2?: Date): number {
  if (!date1 || !date2) {
    return 0;
  }
  // Calculate the time difference in milliseconds
  const timeDiff = date2.getTime() - date1.getTime();
  if (timeDiff < 0) {
    return 0;
  }

  // Convert milliseconds to days
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  // Convert days to months
  return Math.floor(daysDiff / 30);
}

export function getDurationInYears(startDate: string, endDate: string) {
  const start = new Date(startDate.split("Z")[0]);
  const end = new Date(endDate.split("Z")[0]);

  const timeDifference = end.getTime() - start.getTime();
  const dayDifference = Math.round(timeDifference / (1000 * 3600 * 24));
  return dayDifference / 365.25;
}

export function getDurationInMonths(startDate: string, endDate: string) {
  const start = new Date(startDate.split("Z")[0]);
  const end = new Date(endDate.split("Z")[0]);

  const timeDifference = (end.getTime() - start.getTime()) / (1000 * 3600 * 24 * 30);
  return timeDifference < 12 ? Math.round(timeDifference) : Math.floor(timeDifference);
}

export function getDurationInDays(startDate: string, endDate: string) {
  const start = new Date(startDate.split("Z")[0]);
  const end = new Date(endDate.split("Z")[0]);

  const timeDifference = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
  return Math.floor(timeDifference);
}

export function compareRateDate(a, b) {
  return new Date(a.year, a.month + 1, a.day) >= new Date(b.year, b.month + 1, b.day)
    ? 1
    : -1;
}
