import {
  BubbleChart,
  CardMembership,
  CreditCard,
  Edit,
  Equalizer,
  LayersClear,
  List,
  ListAlt,
  Map,
  MultilineChart,
  ShowChart,
  TableChart,
  Timeline
} from "@material-ui/icons";

import { GridWidget, GridWidgetTemplate } from "models/dashboard";

import {
  GeoMapLoaderWidgetKey,
  MidstreamChartWidgetKey,
  MidstreamDataTableWidgetKey,
  TypeWellDetailsWidgetKey,
  TypeWellEditorWidgetKey
} from "../constants/widgets.constants";

export const getWidgetPreview = (
  widget: GridWidget | GridWidgetTemplate | string,
  iconSize?: "default" | "inherit" | "large" | "medium" | "small"
): React.ReactNode => {
  if (!widget) return <></>;
  const size = iconSize ?? "inherit";

  const w = widget as GridWidget;
  const wt = widget as GridWidgetTemplate;
  let type: string = widget as string;

  if (w?.component) {
    type = w.component;
  }
  if (wt?.component) {
    type = wt.component;
  }

  const key = type.toLowerCase();
  switch (key) {
    case "map":
      return <Map fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "xda":
      return <BubbleChart fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "threed":
      return <LayersClear fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "data-table":
      return <TableChart fontSize={size} data-testid={`widget-preview-${key}`} />;
    case MidstreamDataTableWidgetKey:
      return <TableChart fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "chart":
      return <Equalizer fontSize={size} data-testid={`widget-preview-${key}`} />;
    case MidstreamChartWidgetKey:
      return <Equalizer fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "well-card":
      return <CardMembership fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "facility-ticket":
      return <CardMembership fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "info":
      return <CreditCard fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "xdaviewer":
      return <BubbleChart fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "survey-vis":
      return <ShowChart fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "completion":
      return <Timeline fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "sam":
    case "sam-table":
    case "sam-rf-pv":
    case "multiphase-chart":
      return <MultilineChart fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "exploitable-report":
      return <List fontSize={size} data-testid={`widget-preview-${key}`} />;
    case TypeWellEditorWidgetKey:
      return <Edit fontSize={size} data-testid={`widget-preview-${key}`} />;
    case TypeWellDetailsWidgetKey:
      return <ListAlt fontSize={size} data-testid={`widget-preview-${key}`} />;
    case GeoMapLoaderWidgetKey:
      return <Map fontSize={size} data-testid={`widget-preview-${key}`} />;
    default:
      return <></>;
  }
};
