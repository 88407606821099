// eslint-disable-next-line import/no-named-as-default
import { Icon } from "@mdi/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { mdiChevronDown } from "@mdi/js";
import { Alert, Col, Form, Input, InputNumber, Modal, Row, TreeSelect } from "antd";
import _isEqual from "lodash/isEqual";
import { setIsUpdateGeoCalculationJobModalOpen } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { getValidWells } from "api/data";
import { getGroupedPlayZonesByPeriod } from "api/map";

import { useUpdateGeoCalculation } from "../hooks";
import { formatWellListForTextArea, getWellListFromTextArea } from "../utils";
import { convertToTreeData } from "./CreateGeoCalculationJobModal";

const { TextArea } = Input;

export const UpdateGeoCalculationJobModal = () => {
  const dispatch = useDispatch();

  const isUpdateGeoCalculationJobModalOpen = useSelector(
    (state: RootState) => state.organizationSettings.isUpdateGeoCalculationJobModalOpen
  );

  const selectedGeoCalculationJob = useSelector(
    (state: RootState) => state.organizationSettings?.selectedGeoCalculationJob
  );

  const [form] = Form.useForm();

  const updateGeoCalculation = useUpdateGeoCalculation();
  const [errors, setErrors] = useState<string>("");
  const [periodKeys, setPeriodKeys] = useState([]);
  const [selectedIpdbZones, setSelectedIpdbZones] = useState<string[]>([]);
  const [treeData, setTreeData] = useState([]);

  // Set the form values to the selected geo job when the modal is opened
  useEffect(() => {
    if (form && isUpdateGeoCalculationJobModalOpen) {
      form.setFieldsValue({
        ...selectedGeoCalculationJob,
        wellList: formatWellListForTextArea(selectedGeoCalculationJob?.wellList),
        zones: selectedGeoCalculationJob?.zones || []
      });
    }
  }, [selectedGeoCalculationJob, form]);

  useEffect(() => {
    if (updateGeoCalculation.isSuccess) {
      dispatch(setIsUpdateGeoCalculationJobModalOpen(false));
    }
  }, [updateGeoCalculation.isSuccess]);

  useEffect(() => {
    getGroupedPlayZonesByPeriod().then((response) => {
      const treeData = convertToTreeData(response.data) ?? [];
      setPeriodKeys(response.data.map((p) => `period_${p.period}`));
      setTreeData(treeData);
    });
  }, []);

  // Limited to updating the well list for now
  async function handleOk() {
    const values = form.getFieldsValue();
    let wellList = getWellListFromTextArea(values.wellList);
    wellList = await getValidWells(wellList);

    if (wellList.length === 0) {
      setErrors("0 valid wells in the well list.");
      return;
    }
    // If list is the same, close the modal, no update required
    if (_isEqual(selectedGeoCalculationJob.wellList, wellList)) {
      handleClose();
      return;
    }

    updateGeoCalculation.mutateAsync({
      ...selectedGeoCalculationJob,
      wellList
    });
    setErrors("");
  }

  const handleClose = () => {
    updateGeoCalculation.reset();
    setErrors("");
    dispatch(setIsUpdateGeoCalculationJobModalOpen(false));
  };

  const requiredRule = { required: true, message: "This field is required" };

  return (
    <RootContainer>
      <Modal
        closable={false}
        confirmLoading={updateGeoCalculation.isLoading}
        destroyOnClose={true}
        okButtonProps={{ loading: updateGeoCalculation.isLoading, className: "" }}
        maskClosable={false}
        onCancel={handleClose}
        onOk={handleOk}
        open={isUpdateGeoCalculationJobModalOpen}
        title={"Update Geo Calculation Job"}>
        <Form
          preserve={false}
          form={form}
          initialValues={{
            ...selectedGeoCalculationJob,
            wellList: formatWellListForTextArea(selectedGeoCalculationJob?.wellList)
          }}
          name="edit"
          onFinish={handleOk}
          requiredMark={false}>
          <Form.Item name="name" label={<Label>Name</Label>} rules={[requiredRule]}>
            <Input disabled={true} />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="leftWidth"
                label={<Label>Left Width</Label>}
                rules={[requiredRule]}>
                <InputNumber disabled={true} precision={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="rightWidth"
                label={<Label>Right Width</Label>}
                rules={[requiredRule]}>
                <InputNumber disabled={true} precision={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="upHeight"
                label={<Label>Up Height</Label>}
                rules={[requiredRule]}>
                <InputNumber disabled={true} precision={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="downHeight"
                label={<Label>Down Height</Label>}
                rules={[requiredRule]}>
                <InputNumber disabled={true} precision={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="wellList" label={<Label>Well List</Label>}>
                <TextArea autoSize={{ minRows: 15, maxRows: 15 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="zones" label={<Label>Zones</Label>}>
                <TreeSelect
                  disabled={true}
                  showSearch
                  suffixIcon={<Icon path={mdiChevronDown} size={1} />}
                  switcherIcon={
                    <SwitcherIcon>{<Icon path={mdiChevronDown} size={1} />}</SwitcherIcon>
                  }
                  data-testid="geo-zone-select"
                  value={selectedIpdbZones}
                  dropdownStyle={{ overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  maxTagCount={10}
                  onChange={setSelectedIpdbZones}
                  treeDefaultExpandedKeys={periodKeys}
                  treeCheckable={true}
                  treeData={treeData}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {(updateGeoCalculation.isError || errors) && (
          <ErrorMessage
            type="error"
            message={`${errors || "Error occurred while creating job"}`}
          />
        )}
      </Modal>
    </RootContainer>
  );
};

const RootContainer = styled.div``;

const Label = styled.span`
  display: inline-block;
  width: 80px;
`;

const SwitcherIcon = styled.span`
  transform: translateY(2px);
  color: rgba(var(--color-text-rgb), 0.3);
`;

const ErrorMessage = styled(Alert)`
  margin-bottom: 1em;
`;
